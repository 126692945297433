// ** React Imports
import React, { useEffect, useState } from "react";

// ** React Router Imports
import { Link as RouterLink } from "react-router-dom";

// ** Third Party Imports
import moment from "moment";
import { toast } from "react-toastify";

// ** Styles Imports
import "./styles.css";

// ** API URL Imports
import config from "../../config";

const PaymentSuccess = () => {
  // ** Vars
  const paymentDetails = localStorage.getItem("paymentDetails");
  const parsedPaymentDetails = JSON.parse(paymentDetails);

  // ** States
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});

  // ** Handlers
  const updatePaymentStatus = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/ticket/isPaymentDone/${parsedPaymentDetails.ticketId}`,
        {
          method: "PUT",
        }
      );

      const result = await response.json();
      if (result.status === 404) {
        return toast.error(result.message);
      }

      const bookedTime = result.data.course.bookingTimes
        .map((time) => {
          if (result.data.ticket.ticketTime === time._id) {
            return `${moment.utc(time.start).format("hh:mm A")} -
                          ${moment.utc(time.end).format("hh:mm A")}`;
          }
        })
        .toString()
        .replace(/,/g, "");

      const ticketType = result.data.course.ticketTypes
        .map((ticket) => {
          if (result.data.ticket.ticketType === ticket?._id) {
            return ticket.seatType;
          }
        })
        .toString()
        .replace(/,/g, "");

      setData({
        bookedTime: bookedTime,
        ticketType: ticketType,
        ticketQty: result.data.ticket.ticketQty,
        bookedDate: result.data.course.coursePublishDate,
      });

      setLoading(false);
    } catch (error) {
      return toast.error(error);
    }
  };

  const getPromoCode = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${config.API_URL}/ticket/getPromoCode/${parsedPaymentDetails.promoCode}`,
        {
          method: "GET",
        }
      );

      const result = await response.json();
      if (result.status === 404) {
        return toast.error(result.message);
      }
      if (result.data) {
        updatePaymentStatus();
      }
    } catch (error) {
      return toast.error(error);
    }
  };

  // ** Hooks
  useEffect(() => {
    const checkPaymentWasDone = async () => {
      if (parsedPaymentDetails?.sessionId) {
        try {
          setLoading(true);
          const response = await fetch(
            `${config.API_URL}/ticket/checkPaymentStatus/${parsedPaymentDetails?.sessionId}`
          );

          const result = await response.json();
          if (result.data) {
            updatePaymentStatus();
          }
        } catch (error) {
          return toast.error(error.message);
        }
      } else {
        getPromoCode();
      }
    };

    checkPaymentWasDone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ** Renders
  let render = <h2>Not any ticket has booked yet</h2>;

  if (Object.keys(data).length > 0) {
    render = (
      <>
        <h2>Congratulations! 🎉 Payment successfully done.</h2>
        <div
          style={{
            width: "50%",
            lineHeight: "38px",
          }}
        >
          <p>
            <span style={{ fontSize: 16, fontWeight: 600 }}>
              {data.ticketQty} {data.ticketType}{" "}
            </span>
            has booked. <br /> Date -
            <span style={{ fontSize: 16, fontWeight: 600 }}>
              {" "}
              {moment(data.bookedDate).format("MMM DD YYYY")}{" "}
            </span>
            <br />
            Time -
            <span style={{ fontSize: 16, fontWeight: 600 }}>
              {" "}
              {data.bookedTime}{" "}
            </span>
          </p>
        </div>
        <RouterLink
          style={{
            color: "#000",
            fontSize: 16,
            fontWeight: 600,
          }}
          to={"/"}
          onClick={() => localStorage.removeItem("paymentDetails")}
        >
          return to home
        </RouterLink>
      </>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {isLoading ? (
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        render
      )}
    </div>
  );
};

export default PaymentSuccess;
