// ** React Component
import { createContext, useEffect, useMemo, useState } from "react";

// ** React Router Imports
import { useNavigate } from "react-router-dom";

// ** Config Imports
import config from "../config";
import { toast } from "react-toastify";

// ** Defaults
const defaultsProvides = {
  user: null,
  isLoading: false,
  handleLogout: () => null,
  getAuthDetails: () => Boolean,
  handleLogin: () => Promise.resolve(),
};

const AuthContext = createContext(defaultsProvides);

const AuthProviders = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultsProvides.user);
  const [loading, setLoading] = useState(defaultsProvides.isLoading);

  // ** Hooks
  const navigate = useNavigate();

  // ** Handlers
  const getAuthDetails = () => {
    const currentUser = localStorage.getItem("user");
    const parsedCurrentUser = JSON.parse(currentUser);

    if (!parsedCurrentUser?.isAdmin) {
      return false;
    }

    setUser(currentUser);
    return true;
  };

  const handleLogin = async ({ email, password }) => {
    try {
      setLoading(true);
      const requestBody = {
        email: email,
        password: password,
      };
      const response = await fetch(`${config.API_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      if (response.status === 404 || response.status === 401) {
        setLoading(false);
        return toast.error(result.message);
      }

      setLoading(false);
      if (result.data) {
        setUser({
          isAdmin: true,
          email: email,
          password: password,
        });
        const stringifyUser = JSON.stringify({
          isAdmin: true,
          email: email,
          password: password,
        });
        localStorage.setItem("user", stringifyUser);
        navigate("/admin");
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/admin/login");
  };

  const values = useMemo(() => ({
    isLoading: loading,
    handleLogin: handleLogin,
    handleLogout: handleLogout,
    getAuthDetails: getAuthDetails,
  }));

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProviders };
