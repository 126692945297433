// ** React Imports
import React, { useState, useEffect, useContext } from "react";

// ** MUI-X Imports
import { DataGrid } from "@mui/x-data-grid";

// ** Third Party Imports
import moment from "moment";

// ** Context Imports
import { AuthContext } from "../../context/AuthContext";

// ** Styles Imports
import "./styles.css";

// ** API URL Imports
import config from "../../config";
import { Button } from "@mui/material";

// ** Custom
const useGetTickets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState([]);

  const getTicketData = async () => {
    setIsLoading(true);
    const response = await fetch(`${config.API_URL}/ticket/tickets`);
    const result = await response.json();
    if (result.data) {
      setTickets(result.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTicketData();
  }, []);

  return {
    isLoading: isLoading,
    data: tickets,
  };
};

const Admin = () => {
  // ** Custom Hooks
  const { isLoading, data } = useGetTickets();

  // ** Vars
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "email",
      headerName: "Email Address",
      width: 220,
    },
    {
      field: "course",
      headerName: "Course Name",
      width: 180,
    },
    {
      field: "ticketType",
      headerName: "Ticket Type",
      width: 120,
    },
    {
      field: "ticketQty",
      headerName: "Ticket Quantity",
      type: "number",
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      type: "number",
    },
    {
      field: "isPaymentDone",
      headerName: "Payment Status",
    },
    {
      field: "bookedData",
      headerName: "Booking Date",
      type: "number",
    },
    {
      field: "ticketTime",
      headerName: "Booking Time",
    },
  ];

  // ** context
  const { handleLogout } = useContext(AuthContext);

  // ** Handlers
  const rowsData = () => {
    return data.map((ticket) => ({
      id: ticket._id,
      email: ticket.email,
      course: ticket.course.title,
      ticketType: ticket.course?.ticketTypes
        .map((item) => {
          if (ticket.ticketType === item?._id) {
            return item.seatType;
          }
        })
        .toString()
        .replace(/,/g, ""),
      ticketQty: ticket.ticketQty,
      totalCost: ticket.totalCost,
      bookedData: moment(ticket.course?.coursePublishDate).format(
        "MMM DD YYYY"
      ),
      ticketTime: ticket.course?.bookingTimes
        .map((time) => {
          if (ticket.ticketTime === time._id) {
            return `${moment.utc(time.start).format("hh:mm A")} -
                          ${moment.utc(time.end).format("hh:mm A")}`;
          }
        })
        .toString()
        .replace(/,/g, ""),
      isPaymentDone: ticket.isPaymentDone ? "Done" : "Pending",
    }));
  };

  // ** Render
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  let renderDataTable;
  if (data.length > 0) {
    renderDataTable = (
      <div>
        <Button
          size="small"
          variant="outlined"
          onClick={handleLogout}
          sx={{ my: 2, left: "93%", right: "0%" }}
        >
          Logout
        </Button>
        <DataGrid
          rows={rowsData()}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 15, 25]}
          sx={{ height: 370, width: "100%" }}
        />
      </div>
    );
  } else {
    renderDataTable = <h2>No ticket found!</h2>;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {renderDataTable}
    </div>
  );
};

export default Admin;
