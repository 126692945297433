const environment = {
  local: {
    API_URL: "http://localhost:8080",
  },
  production: {
    API_URL: "https://app-tier3tech-course-api.azurewebsites.net", // TODO - replace
  },
};

const config = environment.production;

export default config;
