// ** Third party imports
import { ToastContainer } from "react-toastify";

// ** Navigator Imports
import MainNavigator from "./routes";

// ** Styles Imports
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <div>
      <MainNavigator />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
