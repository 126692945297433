// ** React Imports
import React, { useContext } from "react";

// ** Context Imports
import { AuthContext } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
  const { getAuthDetails } = useContext(AuthContext);

  const isAuthenticated = getAuthDetails();

  console.log(isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" replace />;
  }

  return children;
};

export default ProtectedRoutes;
