// ** React Imports
import React, { useEffect, useMemo, useState } from "react";

// ** MUI Imports
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// ** MUI-Icons Imports
import AirplaneTicketOutlined from "@mui/icons-material/AirplaneTicketOutlined";

// ** MUI-X Imports
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// ** Third party imports
import moment from "moment";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// ** API URL Imports
import config from "../../config";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

// ** Custom Hooks
const useGetTicketType = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleGetTicketData = async () => {
    setIsLoading(true);
    const response = await fetch(`${config.API_URL}/course`, {
      method: "GET",
    });
    const result = await response.json();

    if (result.data) {
      setIsLoading(false);
    }

    return result;
  };

  return {
    isLoading: isLoading,
    handleGetTicketData: handleGetTicketData,
  };
};

const CheckoutForm = () => {
  // ** States
  const [data, setData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [inputs, setInputs] = useState({
    email: "",
    ticketType: "",
    ticketQty: 0,
    ticketTime: "",
    ticketDate: "",
    promoCodeValue: "",
  });

  // ** Custom Hooks
  const { isLoading: ticketTypeLoading, handleGetTicketData } =
    useGetTicketType();

  // ** Hooks
  const navigate = useNavigate();
  useEffect(() => {
    handleGetTicketData().then((result) => {
      setData(result.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ** Handler
  const timeConverter = (time) => {
    let date = new Date(time);
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  };

  const inputChangeHandler = (identifier, value) => {
    setInputs((currentState) => ({
      ...currentState,
      [identifier]: value,
    }));
  };

  const handleSelectCourse = (courseId) => {
    data.forEach((course) => {
      if (course._id === courseId) {
        setSelectedCourse(course);
      }
    });
  };

  // TODO implement stripe payment system
  const handlePayment = async (e) => {
    e.preventDefault();

    const emailRegex = /^\S+@\S+\.\S+$/;
    const isEmailValid = emailRegex.test(inputs.email.trim());

    if (!isEmailValid) {
      return toast.error("Please enter valid email");
    }

    if (inputs.ticketQty === 0) {
      return toast.error("Selected quantity should be more than 0");
    }

    let filteredTicketType = selectedCourse.ticketTypes.filter(
      (ticket) => ticket._id === inputs.ticketType
    );
    let totalCost = inputs.ticketQty * filteredTicketType[0].seatPrice;
    const bookingDate = timeConverter(inputs.ticketDate.toDate());
    setIsLoading(false);
    const response = await fetch(`${config.API_URL}/ticket/book`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        courseId: selectedCourse._id,
        email: inputs.email,
        seatType: filteredTicketType[0]._id,
        ticketPrice: filteredTicketType[0].seatPrice,
        ticketQty: inputs.ticketQty,
        ticketTime: inputs.ticketTime,
        bookedData: bookingDate,
        totalCost: totalCost,
        promoCodeValue: inputs.promoCodeValue,
      }),
    });
    const result = await response.json();
    if (response.status === 400) {
      return toast.error(result.message);
    }

    if (result.data?.url) {
      setIsLoading(false);
      localStorage.setItem(
        "paymentDetails",
        JSON.stringify({
          ticketId: result.data.orderId,
          sessionId: result.data.sessionId,
        })
      );
      window.location.href = `${result.data.url}`;
    } else {
      setIsLoading(false);
      localStorage.setItem(
        "paymentDetails",
        JSON.stringify({
          promoCode: result.data.promoCode,
          ticketId: result.data.orderId,
        })
      );
      navigate("/checkout/success");
    }
  };

  // ** Hooks
  useMemo(() => {
    const date = moment(
      selectedCourse?.coursePublishDate,
      "YYYY-MM-DD"
    ).toDate();
    console.log(date);
    if (selectedCourse?.coursePublishDate) {
      inputChangeHandler("ticketDate", dayjs(date));
    }

    if (Object.keys(selectedCourse).length === 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedCourse]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <AirplaneTicketOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Book ticket
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  size="small"
                  name="email"
                  margin="normal"
                  autoComplete="email"
                  value={inputs.email}
                  label="Email Address"
                  onChange={(e) => inputChangeHandler("email", e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel size="small" id="demo-simple-select-label">
                  Select Course
                </InputLabel>
                <Select
                  size="small"
                  label="Select Course"
                  id="demo-simple-select"
                  value={selectedCourse.title}
                  labelId="demo-simple-select-label"
                  onChange={(e) => handleSelectCourse(e.target.value)}
                >
                  {data?.map((type) => (
                    <MenuItem key={type._id} value={type._id}>
                      {type.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel size="small" id="demo-simple-select-label">
                  Select Ticket Type
                </InputLabel>
                <Select
                  size="small"
                  disabled={isValid}
                  id="demo-simple-select"
                  value={inputs.ticketType}
                  label="Select Ticket Type"
                  labelId="demo-simple-select-label"
                  onChange={(e) =>
                    inputChangeHandler("ticketType", e.target.value)
                  }
                >
                  {selectedCourse.ticketTypes?.map((seat) => (
                    <MenuItem key={seat._id} value={seat._id}>
                      {seat.seatType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  required
                  fullWidth
                  type="number"
                  size="small"
                  margin="normal"
                  disabled={isValid}
                  label="Ticket Qty"
                  value={inputs.ticketQty}
                  onChange={(e) => {
                    inputChangeHandler("ticketQty", parseInt(e.target.value));
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <DatePicker
                  disabled={true}
                  format="DD/MM/YYYY"
                  value={inputs.ticketDate}
                  slotProps={{ textField: { size: "small" } }}
                />
              </FormControl>
              <FormControl fullWidth size="small" margin="normal">
                <InputLabel id="select-time-label" size="small">
                  Select Ticket Time
                </InputLabel>
                <Select
                  size="small"
                  disabled={isValid}
                  id="select-time-label"
                  value={inputs.ticketTime}
                  label="Select Ticket Time"
                  labelId="select-time-label"
                  onChange={(e) =>
                    inputChangeHandler("ticketTime", e.target.value)
                  }
                >
                  {selectedCourse.bookingTimes?.map((time) => (
                    <MenuItem key={time._id} value={time._id}>
                      {moment.utc(time.start).format("hh:mm a")} -{" "}
                      {moment.utc(time.end).format("hh:mm a")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  margin="normal"
                  label="Promo Code"
                  value={inputs.promoCodeValue}
                  onChange={(e) => {
                    inputChangeHandler("promoCodeValue", e.target.value);
                  }}
                />
              </FormControl>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                disabled={ticketTypeLoading || isLoading}
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => handlePayment(e)}
              >
                Payment
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default CheckoutForm;
