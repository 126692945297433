import React from "react";

// ** React Router Import
import { Route, Routes } from "react-router-dom";

// ** Components Imports
import Admin from "../components/Admin";
import AdminLogin from "../components/AdminLogin";
import CheckoutForm from "../components/CheckoutForm";
import PaymentSuccess from "../components/PaymentSuccess";

// ** Protected Routes
import ProtectedRoutes from "./ProtectedRoute";

// ** Context Imports
import { AuthProviders } from "../context/AuthContext";

const MainNavigator = () => {
  return (
    <Routes>
      <Route exact path="/" element={<CheckoutForm />} />
      <Route path="/checkout/success" element={<PaymentSuccess />} />

      <Route exact path="/admin">
        <Route
          index
          path="/admin/login"
          element={
            <AuthProviders>
              <AdminLogin />
            </AuthProviders>
          }
        />

        <Route
          path="/admin"
          element={
            <AuthProviders>
              <ProtectedRoutes>
                <Admin />
              </ProtectedRoutes>
            </AuthProviders>
          }
        />
      </Route>
    </Routes>
  );
};

export default MainNavigator;
